export enum BuildersList {
  tags = 'list_tags',
  tenants = 'list_tenants',
  tenantsTheme = 'list_tenant_theme',
  users = 'list_users',
  portalReports = 'list_portal_reports',
  reports = 'list_reports',
  groupReports = 'list_group_reports',
  roles = 'list_roles',
}

export enum BuildersForm {
  tags = 'form_tags',
  tenantAdd = 'form_tenants',
  tenantEdit = 'form_tenant_edit',
  tenantsTheme = 'form_tenant_theme',
  profile = 'form_user_profile',
  user = 'form_user',
  report = 'form_report',
  groupReport = 'form_group_report',
  roles = 'form_roles',
}
